import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint } from '../../shared/theme'

const ImageWrapper = styled.div`
	position: relative;
	margin: 0 auto;
	width: 100%;

	@media ${breakpoint('tablet')} {
		width: calc(100% - 72px);
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 0;
		padding-top: 100%;

		@media ${breakpoint('mobile')} {
			padding-top: 60%;
		}

		@media ${breakpoint('tablet')} {
			padding-top: 120%;
		}
	}
`

const TwoColumnImage = ({ image }) => {
	return (
		<ImageWrapper>
			<img src={image.mediaItemUrl} className="bg-cover" alt="Placeholder" />
		</ImageWrapper>
	)
}

TwoColumnImage.propTypes = {
	image: PropTypes.object,
}

TwoColumnImage.defaultProps = {
	image: null,
}

export default TwoColumnImage
