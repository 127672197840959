import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LinkButtonAlt from '../../elements/linkButtonAlt'
import { breakpoint } from '../../shared/theme'
import { Content } from '../../elements/wyswyg'

const ColumnInner = styled(Content)`
	@media ${breakpoint('tablet')} {
		padding-right: 2em;
	}
`

const LinkWrap = styled.div`
	margin-top: 48px;

	@media ${breakpoint('tablet')} {
		margin-top: 54px;
	}
`

const TwoColumnContent = ({ content, colorTheme, link }) => {
	return (
		<>
			<ColumnInner
				colorTheme={colorTheme}
				dangerouslySetInnerHTML={{ __html: content }}
			/>
			{link && (
				<LinkWrap>
					<LinkButtonAlt
						path={link.url}
						title={link.title}
						target={link.target}
						colorTheme={colorTheme}
					/>
				</LinkWrap>
			)}
		</>
	)
}

TwoColumnContent.propTypes = {
	content: PropTypes.string,
	colorTheme: PropTypes.string,
	link: PropTypes.object,
}

TwoColumnContent.defaultProps = {
	content: '',
	colorTheme: 'light',
	link: null,
}

export default TwoColumnContent
