import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 70px;
	flex-wrap: wrap;

	@media ${breakpoint('tablet')} {
		padding-right: 2em;
	}

	&::after {
		/* content: ''; */
		width: 33.333%;
		order: 9999;

		@media ${breakpoint('tablet')} {
			max-width: 200px;
		}
	}

	@media ${breakpoint('tablet')} {
		margin-top: 136px;
	}
`

const LogoWrapper = styled.div`
	text-align: center;
	/* width: 33.333%; */
`

const Logo = styled.img`
	max-width: 100%;
	max-height: 40px;

	@media ${breakpoint('tablet')} {
		max-height: 100px;
		max-width: 200px;
		max-height: 60px;
	}

	@media ${breakpoint('desktop')} {
		max-height: 150px;
	}
`

const LogoBlock = ({ logos }) => {
	const { twocolumnImages } = logos

	if (twocolumnImages === null || twocolumnImages.length <= 0) {
		return null
	}

	return (
		<Wrapper>
			{twocolumnImages.map((logo, index) => (
				<LogoWrapper key={index}>
					<Logo src={logo.twocolumnImage.mediaItemUrl} />
				</LogoWrapper>
			))}
		</Wrapper>
	)
}

LogoBlock.propTypes = {
	logos: PropTypes.object,
}

export default LogoBlock
