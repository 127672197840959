import React from 'react'
import styled, { css } from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

import Grid from '../../elements/grid'
import InnerWrapper from '../../elements/innerWrapper'
import SectionTransition from '../../elements/activeSectionDefault'
import TwoColumnContent from '../../components/twoColumnContent'
import TwoColumnImage from '../../components/twoColumnImage'
import LogoBlock from '../../components/twoColumnLogos'

const TwoColumnWrapper = styled(SectionTransition)`
	color: ${(props) =>
		props.colorTheme == 'dark' ? getColor('white') : getColor('black')};
	background: ${(props) =>
		props.colorTheme == 'dark' ? getColor('black') : getColor('white')};
	padding: 48px 0 60px;

	@media ${breakpoint('tablet')} {
		padding: ${(props) => (props.spacing == 'large' ? '200px 0' : '136px 0')};
	}
`

const TwoColumnInner = styled.div`
	grid-column: -1 / 1;
	display: grid;
	grid-template-columns: repeat(12, minmax(0, 1fr));
	column-gap: 16px;
	grid-row-gap: 60px;

	@media ${breakpoint('tablet')} {
		column-gap: 40px;
		grid-column: 2 / 14;
	}

	@media ${breakpoint('desktop')} {
		grid-column: 3 / 13;
	}
`

const Column = styled.div`
	display: flex;
	grid-column: -1 / 1;
	justify-content: ${(props) =>
		props.alignment === 'center' ? 'center' : 'flex-start'};
	flex-direction: column;

	@media ${breakpoint('tablet')} {
		grid-column: span 6;
	}
`

const TwoColumn = ({
	column1,
	column2,
	spacing,
	colourTheme,
	twocolumnLogos,
	isActive,
	columnType1,
	columnType2,
	columnImage1,
	columnImage2,
	columnLink1,
	columnLink2,
}) => {
	return (
		<TwoColumnWrapper
			isActive={isActive}
			spacing={spacing}
			className={`module ${colourTheme == 'dark' ? 'with-background' : ''} `}
			colorTheme={colourTheme}
		>
			<InnerWrapper>
				<Grid>
					<TwoColumnInner>
						<Column
							type={columnType1}
							alignment={
								columnType1 == 'image' || columnType2 == 'image'
									? 'center'
									: 'bottom'
							}
						>
							{columnType1 === 'content' ? (
								<TwoColumnContent
									content={column1}
									colorTheme={colourTheme}
									link={columnLink1}
								/>
							) : (
								<TwoColumnImage image={columnImage1} />
							)}
							{twocolumnLogos && twocolumnLogos.addToColumn === 'left' && (
								<LogoBlock logos={twocolumnLogos} />
							)}
						</Column>
						<Column
							type={columnType2}
							alignment={
								columnType1 == 'image' || columnType2 == 'image'
									? 'center'
									: 'top'
							}
						>
							{columnType2 === 'content' ? (
								<TwoColumnContent
									content={column2}
									colorTheme={colourTheme}
									link={columnLink2}
								/>
							) : (
								<TwoColumnImage image={columnImage2} />
							)}
							{twocolumnLogos && twocolumnLogos.addToColumn === 'right' && (
								<LogoBlock logos={twocolumnLogos} />
							)}
						</Column>
					</TwoColumnInner>
				</Grid>
			</InnerWrapper>
		</TwoColumnWrapper>
	)
}

export default TwoColumn
